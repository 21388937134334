<template>
  <BenefitBar :text-color="content.config?.textColor?.value" :texts="texts" />
</template>

<script setup lang="ts">
import { type CmsElement } from "@/types/cms";
import BenefitBar from "@/components/atoms/BenefitBar";
import { useBenefitBarDetailsStore } from "@/stores/benefitBarDetails";

defineProps<{
  content: CmsElement<{
    textColor: string;
  }>;
}>();

const { texts } = toRefs(useBenefitBarDetailsStore());
</script>
